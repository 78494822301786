<template>
  <div
    :key="key"
    class="wrapper"
  >
    <template v-if="!disabled">
      <div class="label-wrap">
        Template
        <span class="star">*</span>
      </div>
      <v-combobox
        :items="items"
        required="true"
        placeholder="search ID"
        :value="innerValue"
        cache-items
        :loading="loading"
        :rules="[notEmpty]"
        :search-input.sync="text"
        @input="emitInput"
      />
    </template>
    <x-textfield
      v-else
      label="Template"
      :required="true"
      :value="innerValue.text"
      :disabled="disabled"
    />
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import service from '@/modules/Template/services'
import { notEmpty } from '@/utils/validation'

export default {
  props: {
    value: {
      /*
       * ID of the template
       */
      type: String,
      default: null
    },
    channel: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      key: 0,
      text: null,
      loading: false,
      innerValue: this.value,
      items: []
    }
  },
  watch: {
    channel: {
      immediate: true,
      handler () {
        this.search()
        this.key++
        this.items = []
        this.innerValue = ''
      }
    },
    text: {
      immediate: true,
      handler (val) {
        const text = (() => {
          if (!val || val === this.value) return null
          const match = val.match(/(.+) \(ID:.+\)$/)
          if (!match) return val
          return match[1]
        })()
        this.search(text)
      }
    }
  },
  created () {
    if (this.value) this.fetchTemplate(this.value)
  },
  methods: {
    notEmpty () {
      return notEmpty.apply(this, arguments)
    },
    search: debounce(function (val) {
      return this.fetchTemplateList(val)
    }, 150),
    async fetchTemplateList (name) {
      if (this.disabled) return
      this.loading = true
      try {
        const { data } = await service.fetchTemplateList({
          name: name || null,
          type: this.channel,
          limit: 30
        })
        this.emitTemplateContents(data.templates)
        this.items = data.templates.map(this.getOption)
      } catch (e) {
        this.$store.errorNotification = e
      }
      this.loading = false
    },
    async fetchTemplate (id) {
      this.loading = true
      try {
        const {
          data: { templates }
        } = await service.fetchTemplate({ templateId: id })
        this.innerValue = this.getOption(templates[0])
        this.emitTemplateContents(templates)
      } catch (e) {
        this.$store.errorNotification = e
      }
      this.loading = false
    },
    emitTemplateContents (templates) {
      if (!templates.length) return
      const templateContents = templates.reduce((obj, t) => {
        obj[t.id] = t
        return obj
      }, {})
      this.$emit('update:template-contents', templateContents)
    },
    emitInput (val) {
      const { value = '' } = val || {}
      this.$emit('input', value)
    },
    getOption ({ name, id }) {
      return {
        text: `${name} (ID: ${id})`,
        value: id
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.wrapper {
  // All the styles are copied from lazybee
  >>> .v-select {
    margin-top: 0;
    padding-top: 0;
    .v-input__slot {
      margin-bottom: 0;
      &:before {
        content: unset;
      }
    }

    .v-select__slot {
      background: #fff;
      border: 1px solid #d5dbde;
      border-radius: 3px;
      input {
        height: 50px;
        max-height: 50px;
        padding-left: 20px!important;
      }
      .v-input__append-inner {
        margin: 0;
        padding: 0;
        .v-input__icon {
          height: 50px;
          margin: 0 10px;
        }
      }
    }
  }
  >>> .v-messages {
    color: #fb3939!important;
    caret-color: #fb3939!important;
    height: auto !important;
    min-height: 20px;
    font-size: 14px;
  }
  .label-wrap {
    text-align: left;
    font-size: 14px;
    font-weight: 600;
    color: #525862;
    .star {
      color: #ff0039;
    }
  }
}
</style>
