<template>
  <div class="contentWholeWrap taskSchedule">
    <!-- BEGIN header -->
    <v-layout
      row
      wrap
      class="mb-4"
    >
      <h2 class="headline font-weight-medium">
        Task Schedule
      </h2>
    </v-layout>
    <!-- END header -->

    <div class="contentBoxWrap">
      <div class="contentBox">
        <v-layout>
          <v-flex
            md8
            offset-md2
            class="mt-2"
          >
            <v-layout
              v-if="task.name"
              row
              wrap
            >
              <div class="ml-2 taskDetail">
                <p class="subheading font-weight-medium mb-2">
                  <label>Task Name : </label>
                  {{ task.name }}
                </p>
                <p>
                  <label class="labelStyle">Task ID : </label>
                  {{ task.id }}
                </p>
                <p>
                  <label class="labelStyle">Schedule : </label>
                  {{ scheduleHint }}
                </p>
                <p>
                  <label class="labelStyle">Start Time : </label>
                  {{ formatDatetimeFromISOString(task.startTime) }}
                </p>
                <p>
                  <label class="labelStyle">End Time : </label>
                  {{ formatDatetimeFromISOString(task.endTime) }}
                </p>
              </div>
              <v-spacer class="hidden-xs-only" />
              <x-button
                v-if="isTaskEditable"
                v-bind="buttonEditTask"
                class="btnPrimary mb-2"
              />
              <x-table
                v-bind="tableScheme"
                :items="jobs"
                class="taskList mt-4 w100"
              />
            </v-layout>
          </v-flex>
        </v-layout>
      </div>
    </div>
  </div>
</template>

<script>
import { authenticate } from '@/auth/allowAuthMapping.js'
import { STATUS, EXECUTE_STATUS } from '@/const'
import {
  toDateObj,
  composeScheduleHint,
  formatDatetimeFromISOString
} from '@/utils/date'
const statusColorMapping = {
  ready: 'dot-primary',
  executing: 'dot-orange',
  terminated: 'dot-bluey-grey',
  failed: 'dot-warning',
  successful: 'dot-secondary',
  notReady: 'dot-purple'
}

export default {
  props: {
    taskId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      formatDatetimeFromISOString,
      task: {},
      jobs: [],

      buttonEditTask: {
        content: 'Edit Task',
        size: 'medium',
        outline: true,
        prependIcon: 'edit',
        click: () => {
          this.$router.push({
            name: 'TaskEdit',
            params: { taskId: this.taskId, viewOnly: false }
          })
        }
      },

      tableScheme: {
        multiSelectable: false,
        headers: [
          {
            value: 'formatedCreateTime',
            text: 'Execute Time'
          },
          {
            value: 'status',
            text: 'Execute Status',
            render: item => {
              const { status } = item
              const customColor = statusColorMapping[status] || ''
              return `<span class="dot ${customColor}" >${status}</span>`
            }
          },
          {
            value: 'executeDetail',
            text: 'Remark',
            class: 'remarkColumn'
          }
        ]
      }
    }
  },
  computed: {
    scheduleHint () {
      return composeScheduleHint(
        this.task.schedule,
        toDateObj(this.task.startTime)
      )
    },
    isTaskEditable () {
      return (
        authenticate('TaskEdit') &&
        this.task.executeStatus !== EXECUTE_STATUS.terminated &&
        (this.task.status === STATUS.draft ||
          toDateObj(this.task.endTime) > toDateObj())
      )
    }
  },
  async mounted () {
    this.$store.loading = true
    try {
      const { data: tasks } = await this.$Task.fetchTaskDetail({
        taskId: this.taskId
      })
      this.task = tasks
      const { data: jobs } = await this.$Task.fetchJobList({
        taskId: this.taskId
      })
      this.jobs = jobs.map(job => ({
        ...job,
        formatedCreateTime: formatDatetimeFromISOString(job.createTime)
      }))
    } catch (e) {
      this.$store.errorNotification = e
    }
    this.$store.loading = false
  }
}
</script>

<style lang="stylus" scoped>
.taskDetail {
  p {
    margin: 0;
  }
}

.taskList {
  >>> table.v-table {
    thead th.remarkColumn,
    tbody .remark-td-wrapper {
      word-break: break-all;
      width: 360px !important;
    }
  }
}
</style>
