<template>
  <div class="task-schedule-selector">
    <x-select-filter
      v-model="scheme.schedule.value"
      v-bind="scheme.schedule"
      :items="scheduleOptionsItems"
      :disabled="viewOnly || !isEditable"
      @change="onChange"
    />

    <v-layout
      row
      wrap
    >
      <v-flex class="mt-1">
        <x-textfield
          v-if="viewOnly || !isEditable"
          :label="executeStartTimeLabel"
          :value="startTime && formatDatetimeFromISOString(startTime)"
          :disabled="viewOnly || !isEditable"
          class="w100"
        />
        <x-datetime-picker
          v-else
          ref="startTimePicker"
          :label="executeStartTimeLabel"
          :start-date="toDateObj(startTime)"
          required
          single-date
          time-format="HH:mm"
          class="w100 mb-4"
          @onChange="onStartTimePickerChange"
        />
      </v-flex>
      <v-flex
        v-if="isSchedule"
        class="mt-1 pl-2"
      >
        <x-textfield
          v-if="viewOnly"
          label="Execute End Time"
          :value="endTime && formatDatetimeFromISOString(endTime)"
          :disabled="viewOnly"
          class="w100"
        />
        <x-datetime-picker
          v-else
          ref="endTimePicker"
          label="Execute End Time"
          :start-date="toDateObj(endTime)"
          required
          single-date
          time-format="HH:mm"
          class="w100 mb-4"
          @onChange="onEndTimePickerChange"
        />
      </v-flex>
    </v-layout>

    <hint
      v-if="!!scheme.schedule.value && scheduleHint"
      class="mb-3"
    >
      {{ scheduleHint }}
    </hint>
  </div>
</template>

<script>
import {
  isTaskTimeAllowed,
  localDateToISOString,
  toDateObj,
  formatDatetimeFromISOString,
  composeScheduleHint
} from '@/utils/date'
import Hint from '@/components/Hint'
const scheduleOptions = ['Daily', 'Weekly', 'Monthly']

export default {
  components: {
    Hint
  },
  props: {
    viewOnly: {
      type: Boolean,
      default: true
    },
    isSchedulable: {
      type: Boolean,
      default: true
    },
    schedule: {
      type: String,
      default: ''
    },
    startTime: {
      type: String,
      default: ''
    },
    endTime: {
      type: String,
      default: ''
    },
    task: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      toDateObj,
      formatDatetimeFromISOString,
      startDate: toDateObj(this.startTime),
      endDate: toDateObj(this.endTime),
      scheme: {
        schedule: {
          label: 'Task Schedule',
          placeholder: 'schedule',
          value: this.schedule,
          rules: [],
          required: true
        }
      }
    }
  },
  computed: {
    isSchedule () {
      return !!this.scheme.schedule.value
    },
    isEditable () {
      return !this.task.hasJob
    },
    executeStartTimeLabel () {
      return this.isSchedule ? 'Execute Start Time' : 'Execute Time'
    },
    scheduleHint () {
      return composeScheduleHint(this.scheme.schedule.value, this.startDate)
    },
    scheduleOptionsItems () {
      let options = [{ text: 'Not Repeat', value: '' }]
      if (this.isSchedulable) {
        options = options.concat(
          scheduleOptions.map(item => ({
            text: item,
            value: item
          }))
        )
      }
      return options
    }
  },
  watch: {
    startTime (val) {
      this.startDate = toDateObj(val)
    },
    endTime (val) {
      this.endDate = toDateObj(val)
    }
  },
  methods: {
    validate () {
      if (!isTaskTimeAllowed(this.startDate)) {
        throw new Error('Execution time is too early')
      }
      if (this.endDate < this.startDate) {
        throw new Error('EndTime should be greater than or equal to StartTime')
      }
      if (
        this.scheme.schedule.value === 'Monthly' &&
        this.startDate.getDate() > 28
      ) {
        throw new Error(
          'Monthly execute date can only be selected from 1st to 28th of the month'
        )
      }
      return true
    },
    onChange () {
      if (!this.isSchedule) this.endDate = this.startDate
      try {
        this.validate()
        this.$emit('change', {
          schedule: this.scheme.schedule.value,
          startTime: localDateToISOString(this.startDate),
          endTime: localDateToISOString(this.endDate)
        })
      } catch (e) {
        this.$store.errorNotification = e
      }
    },
    onStartTimePickerChange (data) {
      try {
        this.startDate = data.startDate
        if (!this.isSchedule) this.endDate = this.startDate
        this.validate()
        this.clearErrorMessage()
        this.onChange()
      } catch (e) {
        this.$refs.startTimePicker.setErrorMessage(e.message)
      }
    },
    onEndTimePickerChange (data) {
      try {
        this.endDate = data.startDate
        this.validate()
        this.clearErrorMessage()
        this.onChange()
      } catch (e) {
        this.$refs.endTimePicker.setErrorMessage(e.message)
      }
    },
    clearErrorMessage () {
      this.$refs.startTimePicker &&
        this.$refs.startTimePicker.setErrorMessage('')
      this.$refs.endTimePicker && this.$refs.endTimePicker.setErrorMessage('')
    }
  }
}
</script>

<style lang="stylus" scoped>
.task-schedule-selector >>> .dateTimePickerWrapper .calendarTrigger {
  min-width: auto;
}
</style>
