<template>
  <div class="contentWholeWrap">
    <!-- BEGIN header -->
    <v-layout
      row
      wrap
      class="mb-4"
    >
      <h2 class="headline font-weight-medium">
        {{ viewOnly ? 'Task Detail' : 'Edit Task' }}
      </h2>
      <v-spacer class="hidden-xs-only" />
      <div class="createInfo caption text-md-right mr-3">
        <p>
          <label class="labelStyle">Creator : </label>
          {{ task.ownerName }}
        </p>
        <p>
          <label class="labelStyle">Create Date : </label>
          {{ taskCreateTime }}
        </p>
      </div>
    </v-layout>
    <!-- END header -->

    <TaskForm
      v-if="task.campaignId"
      :view-only="viewOnly"
      :task="task"
    />
  </div>
</template>

<script>
import TaskForm from './TaskForm.vue'
import { formatDatetimeFromISOString } from '@/utils/date'

export default {
  components: {
    TaskForm
  },
  props: {
    viewOnly: {
      type: Boolean,
      default: false
    },
    taskId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      task: {}
    }
  },
  computed: {
    taskCreateTime () {
      return (
        this.task.createTime &&
        formatDatetimeFromISOString(this.task.createTime)
      )
    }
  },
  async mounted () {
    this.$store.loading = true
    try {
      const { data } = await this.$Task.fetchTaskDetail({
        taskId: this.taskId
      })
      if (!data) {
        return (this.$store.errorNotification = 'Task not found')
      }
      this.task = data
    } catch (e) {
      this.$store.errorNotification = e
    }
    this.$store.loading = false
  }
}
</script>

<style lang="stylus" scoped>
.createInfo {
  p {
    margin: 0;
  }
}
</style>
