<template>
  <div class="contentWholeWrap">
    <!-- BEGIN header -->
    <v-layout
      row
      wrap
      class="mb-4"
    >
      <h2 class="headline font-weight-medium">
        Create Task
      </h2>
    </v-layout>
    <!-- END header -->

    <TaskForm
      :default-values="defaultValues"
      :view-only="viewOnly"
    />
  </div>
</template>

<script>
import TaskForm from './TaskForm.vue'

export default {
  components: {
    TaskForm
  },
  props: {
    campaignId: {
      type: String,
      default: ''
    },
    groupId: {
      type: String,
      default: ''
    },
    segmentId: {
      type: String,
      default: ''
    },
    viewOnly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      defaultValues: {
        campaignId: this.campaignId,
        groupId: this.groupId,
        segmentId: this.segmentId
      }
    }
  }
}
</script>

<style lang="stylus" scoped></style>
