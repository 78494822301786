<template>
  <div class="contentWholeWrap">
    <!-- BEGIN header -->
    <v-layout
      row
      wrap
      class="searchBar"
    >
      <h2
        class="headline font-weight-medium"
        @click="$refs.buttonSearch.click()"
      >
        Task List
      </h2>
      <v-spacer class="hidden-xs-only" />
      <x-formgroup
        v-model="scheme.valid"
        class="d-box-md-and-up w100-md-and-down"
      >
        <x-textfield
          v-model="scheme.taskName.value"
          v-bind="scheme.taskName"
          class="small"
          @keyup.enter="() => $refs.buttonSearch.click()"
        />
        <x-datetime-picker
          v-bind="scheme.datetimePicker"
          :start-date="startAfter"
          :end-date="startBefore"
          time-format="HH:mm"
          class="small"
          @onChange="onChangeHandler"
        />

        <x-button
          ref="buttonSearch"
          v-bind="buttonSearch"
          class="btnSecondary btnXsBlock"
        />

        <span class="separation-line">
          <a
            :href="downloadCsvUrl"
            download
            class="btnLink"
          >
            <x-button
              v-bind="buttonDownload"
              class="btnSecondary btnXsBlock"
            />
          </a>
        </span>
      </x-formgroup>
    </v-layout>
    <!-- END header -->

    <!-- BEGIN content -->
    <div class="contentBoxWrap">
      <div class="contentBox">
        <v-layout
          row
          wrap
        >
          <x-button
            v-if="authenticate('TaskCreate')"
            v-bind="buttonCreate"
            class="btnPrimary btnXsBlock"
          />
          <v-spacer class="hidden-xs-only" />

          <!-- BEGIN filter -->
          <x-formgroup
            v-model="scheme.valid"
            class="table-filters d-box-md-and-up w100-md-and-down"
          >
            <span class="mr-2 labelStyle">Filter</span>
            <x-select-filter
              v-model="filterStatus.value"
              v-bind="filterStatus"
              class="small"
              @change="() => fetchTaskList()"
            />
            <x-select-filter
              v-model="filterExecuteStatus.value"
              v-bind="filterExecuteStatus"
              class="small filter-execute-status"
              :attach="true"
              @change="() => fetchTaskList()"
            />
            <x-select-filter
              v-model="filter.channel"
              placeholder="All Channel"
              :items="filterChannels"
              class="small"
              @change="() => fetchTaskList()"
            />
          </x-formgroup>
          <!-- END filter -->
        </v-layout>

        <hint>
          After you click the "submit" button of the task, the blast is
          scheduled and cannot be modified.
          <br>
          If you want to terminate the job, please contact "resource manager".
        </hint>

        <task-list-table
          :query="taskListQuery"
          :show-campaign="true"
        />
      </div>
    </div>
    <!-- END content -->
  </div>
</template>

<script>
import { authenticate } from '@/auth/allowAuthMapping.js'
import Hint from '@/components/Hint'
import TaskListTable from '../components/TaskListTable'
import { localDateToISOString } from '@/utils/date'
import { STATUS, EXECUTE_STATUS } from '@/const'
import company from '@/mixins/company'

export default {
  components: { TaskListTable, Hint },
  mixins: [company],
  data () {
    return {
      startAfter: undefined,
      startBefore: undefined,

      // BEGIN search
      scheme: {
        taskName: {
          type: 'text',
          name: 'taskName',
          placeholder: 'Task Name'
        },

        datetimePicker: {
          singleDate: false,
          alignRight: true,
          placeholder: 'select'
        }
      },

      buttonSearch: {
        content: 'Search',
        size: 'small',
        click: () => this.fetchTaskList()
      },
      // END search

      // BEGIN filter
      filterStatus: {
        placeholder: 'All Status',
        items: [{ text: 'All', value: null }].concat(
          Object.keys(STATUS).map(status => ({
            text: this.$t(`Task.status_list.${status}`),
            value: status
          }))
        )
      },

      filterExecuteStatus: {
        placeholder: 'All Execute Status',
        items: [{ text: 'All', value: null }].concat(
          Object.keys(EXECUTE_STATUS).map(status => ({
            text: this.$t(`Task.execute_status_list.${status}`),
            value: status
          }))
        )
      },

      filter: {
        channel: null
      },

      // END filter

      buttonDownload: {
        content: 'Download CSV',
        size: 'small',
        outline: true,
        prependIcon: 'cloud_download'
      },

      buttonCreate: {
        content: 'Create Task',
        size: 'medium',
        prependIcon: 'add',
        click: () => {
          this.$router.push({ name: 'TaskCreate' })
        }
      },

      taskListQuery: {}
    }
  },
  computed: {
    filterChannels () {
      return this.company.taskChannels.map(c => ({
        text: this.$t(`Task.channel.${c}`),
        value: c
      }))
    },
    downloadCsvUrl () {
      const searchParams = new URLSearchParams()
      Object.keys(this.taskListQuery).forEach(key => {
        const value = this.taskListQuery[key]
        if (!value) return
        searchParams.set(key, value)
      })
      return `/api/v1/companies/${
        this.companyId
      }/tasks/csv?${searchParams.toString()}`
    }
  },
  methods: {
    authenticate (modelAction) {
      return authenticate(modelAction)
    },
    onChangeHandler: function (data) {
      this.startAfter = data.startDate
      this.startBefore = data.endDate
    },
    fetchTaskList () {
      this.taskListQuery = {
        name: this.scheme.taskName.value,
        status: this.filterStatus.value,
        executeStatus: this.filterExecuteStatus.value,
        channel: this.filter.channel,
        startAfter: this.startAfter && localDateToISOString(this.startAfter),
        startBefore: this.startBefore && localDateToISOString(this.startBefore)
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.filter-execute-status >>> .v-menu__content {
  max-height: 352px !important;
}

// for download csv separation line
@media screen and (min-width: 450px) {
  .separation-line {
    border-left: 1px solid $light-grey;
    padding-left: 8px;
    margin-left: 8px;
    display: inline-block;
  }
}

@media screen and (max-width: 450px) {
  .separation-line {
    button {
      margin: 10px 0 15px;
    }
  }
}
</style>
