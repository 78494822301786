var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contentBoxWrap"},[_c('div',{staticClass:"contentBox"},[_c('v-layout',[_c('v-flex',{attrs:{"md6":"","offset-md3":""}},[_c('x-formgroup',{ref:"form",staticClass:"w100",on:{"submit":function($event){$event.preventDefault();return (() => _vm.$refs.submitButton.click()).apply(null, arguments)}}},[_c('div',{staticClass:"pa-2"},[_c('x-select-filter',_vm._b({attrs:{"disabled":_vm.viewOnly || !_vm.isEditable,"items":_vm.campaignOptionsItems},on:{"change":_vm.fetchSegmentList},model:{value:(_vm.scheme.campaign.value),callback:function ($$v) {_vm.$set(_vm.scheme.campaign, "value", $$v)},expression:"scheme.campaign.value"}},'x-select-filter',_vm.scheme.campaign,false)),_c('p',{staticClass:"filterHint"},[_c('label',{staticClass:"d-block labelStyle"},[_vm._v("Campaign Description")]),_c('span',{class:[{ placeholder: !_vm.scheme.campaign.value }, 'longText']},[_vm._v(" "+_vm._s(_vm.selectedCampaign.description || 'Please select campaign first')+" ")])]),_c('x-textfield',_vm._b({attrs:{"disabled":_vm.viewOnly || !_vm.isEditable},model:{value:(_vm.scheme.taskName.value),callback:function ($$v) {_vm.$set(_vm.scheme.taskName, "value", $$v)},expression:"scheme.taskName.value"}},'x-textfield',_vm.scheme.taskName,false)),_c('div',{staticClass:"position-relative"},[_c('x-select-filter',_vm._b({attrs:{"items":_vm.segmentOptionsItems,"disabled":_vm.viewOnly || !_vm.isEditable},on:{"change":_vm.getSegmentGroups},model:{value:(_vm.scheme.segment.value),callback:function ($$v) {_vm.$set(_vm.scheme.segment, "value", $$v)},expression:"scheme.segment.value"}},'x-select-filter',_vm.scheme.segment,false)),(_vm.scheme.segment.value)?_c('a',{staticClass:"filterHint desktop-align-right",attrs:{"href":`${_vm.dataPlatformUrl}/#/crm/viewPeopleLibrary?peopleLibraryId=${
                    _vm.scheme.segment.value
                  }`,"target":"_blank"}},[_vm._v(" View segment ("+_vm._s(_vm.scheme.segment.value)+") detail")]):_vm._e()],1),_c('x-select-filter',_vm._b({attrs:{"items":_vm.groupOptionsItems,"disabled":_vm.viewOnly || !_vm.isEditable},model:{value:(_vm.scheme.group.value),callback:function ($$v) {_vm.$set(_vm.scheme.group, "value", $$v)},expression:"scheme.group.value"}},'x-select-filter',_vm.scheme.group,false)),_c('TaskScheduleSelector',{ref:"schedule",attrs:{"view-only":_vm.viewOnly,"task":_vm.task,"is-schedulable":_vm.selectedSegment.isScheduled,"schedule":_vm.task.schedule || _vm.taskSchedule,"start-time":_vm.data.startTime || _vm.task.startTime,"end-time":_vm.data.endTime || _vm.task.endTime},on:{"change":_vm.onScheduleChange}}),_c('x-select-filter',_vm._b({attrs:{"disabled":_vm.viewOnly || !_vm.isEditable},on:{"change":_vm.updateTemplateOptions},model:{value:(_vm.scheme.channel.value),callback:function ($$v) {_vm.$set(_vm.scheme.channel, "value", $$v)},expression:"scheme.channel.value"}},'x-select-filter',_vm.schemeChannel,false)),(
                _vm.scheme.channel.value && !_vm.isOutbound && !_vm.isExclusiveProducts
              )?_c('TemplateSelector',{attrs:{"channel":_vm.scheme.channel.value,"disabled":_vm.viewOnly || !_vm.isEditable},on:{"update:template-contents":function($event){_vm.templateContents = $event}},model:{value:(_vm.form.template),callback:function ($$v) {_vm.$set(_vm.form, "template", $$v)},expression:"form.template"}}):_vm._e(),(
                _vm.scheme.channel.value && !_vm.isAppPush && !_vm.isExclusiveProducts
              )?_c('x-select-filter',_vm._b({attrs:{"disabled":_vm.viewOnly || !_vm.isEditable},model:{value:(_vm.scheme.messageType.value),callback:function ($$v) {_vm.$set(_vm.scheme.messageType, "value", $$v)},expression:"scheme.messageType.value"}},'x-select-filter',_vm.schemeMessageType,false)):_vm._e()],1),_c('div',[(
                _vm.form.template &&
                  !_vm.isOutbound &&
                  !_vm.isAppPush &&
                  !_vm.isExclusiveProducts
              )?_c('x-button',_vm._b({staticClass:"btnSecondary"},'x-button',_vm.scheme.buttonPreview,false)):_vm._e(),_c('v-layout',{staticClass:"mt-3 actionBtnGroup",attrs:{"row":"","wrap":""}},[_c('x-button',_vm._b({staticClass:"btnInfo"},'x-button',_vm.scheme.buttonCancel,false)),(!_vm.viewOnly)?_c('x-button',_vm._b({ref:"submitButton",staticClass:"btnSecondary",attrs:{"disabled":_vm.$store.loading}},'x-button',_vm.scheme.buttonSave,false)):_vm._e(),_c('v-spacer'),(!_vm.isOutbound && !_vm.isAppPush && !_vm.isExclusiveProducts)?_c('span',{on:{"click":_vm.openSendingTest}},[_c('x-button',_vm._b({staticClass:"btnPrimary"},'x-button',_vm.scheme.buttonSendtest,false))],1):_vm._e()],1)],1)])],1)],1)],1),(!_vm.isOutbound)?_c('sms-preview-dialog',{attrs:{"title":_vm.dialogSmsPreview.item.name,"content":_vm.dialogSmsPreview.item.content},on:{"confirm":function($event){_vm.dialogSmsPreview.value = false}},model:{value:(_vm.dialogSmsPreview.value),callback:function ($$v) {_vm.$set(_vm.dialogSmsPreview, "value", $$v)},expression:"dialogSmsPreview.value"}}):_vm._e(),(!_vm.isOutbound)?_c('edm-preview',{staticClass:"displayInline",attrs:{"subject":_vm.dialogEdmPreview.item.subject},on:{"confirm":function($event){_vm.dialogEdmPreview.value = false}},model:{value:(_vm.dialogEdmPreview.value),callback:function ($$v) {_vm.$set(_vm.dialogEdmPreview, "value", $$v)},expression:"dialogEdmPreview.value"}}):_vm._e(),(
      !_vm.isOutbound &&
        !_vm.isExclusiveProducts &&
        _vm.scheme.channel.value &&
        _vm.form.template &&
        _vm.templateContents[_vm.form.template]
    )?_c('sending-test',{attrs:{"type":_vm.scheme.channel.value,"template-subject":_vm.templateContents[_vm.form.template].subject,"template-content":_vm.templateContents[_vm.form.template].content,"template-id":_vm.form.template},model:{value:(_vm.dialogSendingTest.value),callback:function ($$v) {_vm.$set(_vm.dialogSendingTest, "value", $$v)},expression:"dialogSendingTest.value"}}):_vm._e()],1)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }