<template>
  <div class="contentBoxWrap">
    <div class="contentBox">
      <v-layout>
        <v-flex
          md6
          offset-md3
        >
          <x-formgroup
            ref="form"
            class="w100"
            @submit.prevent="() => $refs.submitButton.click()"
          >
            <!-- BEGIN input group -->
            <div class="pa-2">
              <x-select-filter
                v-model="scheme.campaign.value"
                v-bind="scheme.campaign"
                :disabled="viewOnly || !isEditable"
                :items="campaignOptionsItems"
                @change="fetchSegmentList"
              />
              <p class="filterHint">
                <label class="d-block labelStyle">Campaign Description</label>
                <span
                  :class="[{ placeholder: !scheme.campaign.value }, 'longText']"
                >
                  {{
                    selectedCampaign.description ||
                      'Please select campaign first'
                  }}
                </span>
              </p>
              <x-textfield
                v-model="scheme.taskName.value"
                v-bind="scheme.taskName"
                :disabled="viewOnly || !isEditable"
              />

              <div class="position-relative">
                <x-select-filter
                  v-model="scheme.segment.value"
                  v-bind="scheme.segment"
                  :items="segmentOptionsItems"
                  :disabled="viewOnly || !isEditable"
                  @change="getSegmentGroups"
                />
                <a
                  v-if="scheme.segment.value"
                  class="filterHint desktop-align-right"
                  :href="
                    `${dataPlatformUrl}/#/crm/viewPeopleLibrary?peopleLibraryId=${
                      scheme.segment.value
                    }`
                  "
                  target="_blank"
                >
                  View segment ({{ scheme.segment.value }}) detail</a>
              </div>

              <x-select-filter
                v-model="scheme.group.value"
                v-bind="scheme.group"
                :items="groupOptionsItems"
                :disabled="viewOnly || !isEditable"
              />

              <!-- BEGIN Task Schedule -->
              <TaskScheduleSelector
                ref="schedule"
                :view-only="viewOnly"
                :task="task"
                :is-schedulable="selectedSegment.isScheduled"
                :schedule="task.schedule || taskSchedule"
                :start-time="data.startTime || task.startTime"
                :end-time="data.endTime || task.endTime"
                @change="onScheduleChange"
              />

              <x-select-filter
                v-model="scheme.channel.value"
                v-bind="schemeChannel"
                :disabled="viewOnly || !isEditable"
                @change="updateTemplateOptions"
              />

              <TemplateSelector
                v-if="
                  scheme.channel.value && !isOutbound && !isExclusiveProducts
                "
                v-model="form.template"
                :channel="scheme.channel.value"
                :disabled="viewOnly || !isEditable"
                @update:template-contents="templateContents = $event"
              />
              <x-select-filter
                v-if="
                  scheme.channel.value && !isAppPush && !isExclusiveProducts
                "
                v-model="scheme.messageType.value"
                v-bind="schemeMessageType"
                :disabled="viewOnly || !isEditable"
              />
            </div>
            <!-- END input group -->
            <!-- BEGIN button group -->
            <div>
              <x-button
                v-if="
                  form.template &&
                    !isOutbound &&
                    !isAppPush &&
                    !isExclusiveProducts
                "
                v-bind="scheme.buttonPreview"
                class="btnSecondary"
              />
              <v-layout
                row
                wrap
                class="mt-3 actionBtnGroup"
              >
                <x-button
                  v-bind="scheme.buttonCancel"
                  class="btnInfo"
                />
                <x-button
                  v-if="!viewOnly"
                  ref="submitButton"
                  v-bind="scheme.buttonSave"
                  :disabled="$store.loading"
                  class="btnSecondary"
                />
                <v-spacer />
                <span
                  v-if="!isOutbound && !isAppPush && !isExclusiveProducts"
                  @click="openSendingTest"
                >
                  <x-button
                    v-bind="scheme.buttonSendtest"
                    class="btnPrimary"
                  />
                </span>
              </v-layout>
            </div>
            <!-- END button group -->
          </x-formgroup>
        </v-flex>
      </v-layout>
    </div>
    <sms-preview-dialog
      v-if="!isOutbound"
      v-model="dialogSmsPreview.value"
      :title="dialogSmsPreview.item.name"
      :content="dialogSmsPreview.item.content"
      @confirm="dialogSmsPreview.value = false"
    />
    <edm-preview
      v-if="!isOutbound"
      v-model="dialogEdmPreview.value"
      :subject="dialogEdmPreview.item.subject"
      class="displayInline"
      @confirm="dialogEdmPreview.value = false"
    />
    <sending-test
      v-if="
        !isOutbound &&
          !isExclusiveProducts &&
          scheme.channel.value &&
          form.template &&
          templateContents[form.template]
      "
      v-model="dialogSendingTest.value"
      :type="scheme.channel.value"
      :template-subject="templateContents[form.template].subject"
      :template-content="templateContents[form.template].content"
      :template-id="form.template"
    />
  </div>
</template>

<script>
import SendingTest from '../components/SendingTest'
import {
  notEmpty,
  maxLength,
  oneOf,
  greaterThanCurrentTime
} from '@/utils/validation'
import { localDateToISOString, formatDatetimeFromISOString } from '@/utils/date'
import SmsPreviewDialog from '@/modules/Template/components/SmsPreviewDialog'
import EdmPreview from '@/modules/Template/components/EdmPreview'
import TaskScheduleSelector from '../components/TaskScheduleSelector'
import { convertTemplateToHtml } from '@/components/SectionUI/const/sectionHtml'
import TemplateSelector from '../components/TemplateSelector.vue'
import { STATUS, dataPlatform } from '@/const'
import company from '@/mixins/company'

export default {
  components: {
    TemplateSelector,
    EdmPreview,
    SendingTest,
    SmsPreviewDialog,
    TaskScheduleSelector
  },
  mixins: [company],
  props: {
    viewOnly: {
      type: Boolean,
      default: true
    },
    defaultValues: {
      type: Object,
      default: () => ({})
    },
    task: {
      type: Object,
      default: () => ({
        channelParams: {}
      })
    }
  },
  data () {
    return {
      formatDatetimeFromISOString,
      taskSchedule: this.task.schedule || '',
      data: {
        startTime: '',
        endTime: '',
        ...this.task
      },
      dialogSmsPreview: {
        value: false,
        item: {}
      },
      dialogSendingTest: {
        value: false
      },
      templateContents: {},
      campaignSegments: [],
      campaignOptions: [],
      selectedSegment: [],
      dialogEdmPreview: {
        value: false,
        item: {}
      },
      form: {
        template: this.task.templateId
      },
      scheme: {
        campaign: {
          label: 'Campaign',
          placeholder: 'search campaign ID',
          required: true,
          rules: [notEmpty, maxLength(40)].map(x => x.bind(this)),
          value: this.task.campaignId
        },

        taskName: {
          label: 'Task Name',
          type: 'text',
          placeholder: 'maximum length 50 characters',
          required: true,
          rules: [notEmpty, maxLength(50)].map(x => x.bind(this)),
          value: this.task.name
        },

        segment: {
          label: 'Segment',
          placeholder: 'search ID',
          required: true,
          rules: [notEmpty, maxLength(40)].map(x => x.bind(this)),
          value: this.task.segmentId
        },

        group: {
          label: 'Group',
          placeholder: 'please select',
          required: true,
          rules: [notEmpty, maxLength(40)].map(x => x.bind(this)),
          value: this.task.groupId
        },

        channel: {
          value: this.task.channel
        },
        messageType: {
          value: this.task.messageType || 'internal_marketing'
        },
        buttonPreview: {
          content: 'Preview',
          size: 'small',
          prependIcon: 'visibility',
          flat: true,
          click: () => {
            if (!this.templateContents) {
              return (this.$store.errorNotification =
                'Fetching template data, please wait')
            }
            if (!this.form.template) {
              return (this.$store.errorNotification =
                'Please select template first')
            }
            if (!this.templateContents[this.form.template]) {
              return (this.$store.errorNotification =
                'Please re-select template')
            }

            const selectedTemplate = this.templateContents[this.form.template]
            if (this.scheme.channel.value === 'SMS') {
              this.dialogSmsPreview.value = true
              this.dialogSmsPreview.item = selectedTemplate
            } else if (this.scheme.channel.value === 'EDM') {
              this.$store.builderProperty.rowSectionList = JSON.parse(
                selectedTemplate.content
              )
              this.dialogEdmPreview.value = true
              this.dialogEdmPreview.item = selectedTemplate
            }
          },
          tooltip: {
            direction: 'top',
            content: 'Preview Template',
            contentClass: 'tooltipClass'
          }
        },

        buttonCancel: {
          content: 'Cancel',
          size: 'large',
          outline: true,
          click: () => {
            this.$router.go(-1)
          }
        },

        buttonSave: {
          content: 'Save',
          size: 'large',
          click: () => this.submit(STATUS.draft)
        },
        buttonSendtest: {
          content: 'Sending Test',
          size: 'medium',
          outline: true,
          prependIcon: 'send'
        }
      },
      dataPlatformUrl: dataPlatform.URL[this.$store.env]
    }
  },
  computed: {
    schemeChannel () {
      const { taskChannels } = this.company
      return {
        label: 'Channel',
        placeholder: 'please select',
        required: true,
        rules: [notEmpty, oneOf(taskChannels)].map(x => x.bind(this)),
        items: taskChannels.map(c => ({
          text: this.$t(`Task.channel.${c}`),
          value: c
        }))
      }
    },
    schemeMessageType () {
      const { taskMessageType } = this.company
      return {
        label: 'Message Type',
        placeholder: 'please select',
        required: true,
        items: taskMessageType.map(type => ({
          text: this.$t(`Task.messageType.${type}`),
          value: type
        }))
      }
    },
    isOutbound () {
      return this.scheme.channel.value === 'OUTBOUND'
    },
    isAppPush () {
      return this.scheme.channel.value === 'APP_PUSH'
    },
    isExclusiveProducts () {
      return this.scheme.channel.value === 'EXCLUSIVE_PRODUCTS'
    },
    isEditable () {
      return !this.task.hasJob
    },
    campaignOptionsItems () {
      return this.campaignOptions.map(({ id, name }) => ({
        text: `${name} (ID: ${id})`,
        value: id
      }))
    },
    selectedCampaign () {
      const campaignId = this.scheme.campaign.value || this.task.campaignId
      return this.campaignOptions.find(({ id }) => id === campaignId) || []
    },
    segmentOptionsItems () {
      if (!this.scheme.campaign.value) {
        return [
          {
            text: 'Please select campaign first',
            disabled: true
          }
        ]
      }
      return this.campaignSegments.map(({ id, description }) => ({
        text: `(ID: ${id}) ${description}`,
        value: id
      }))
    },
    groupOptionsItems () {
      if (!this.scheme.campaign.value) {
        return [
          {
            text: 'Please select campaign first',
            disabled: true
          }
        ]
      }
      if (!this.scheme.segment.value) {
        return [
          {
            text: 'Please select segment first',
            disabled: true
          }
        ]
      }
      const groups = this.selectedSegment.groups
      if (!groups) return []

      return groups.map(({ groupId, groupName, isSend }) => ({
        text: `(ID: ${groupId}) ${groupName}`,
        value: groupId,
        disabled: !isSend
      }))
    }
  },
  async mounted () {
    this.$store.loading = true
    try {
      if (this.task.campaignId) {
        const { data: campaign } = await this.$Campaign.fetchCampaignDetail({
          campaignId: this.task.campaignId
        })
        this.campaignOptions = [campaign]
        await this.fetchSegmentList(campaign.id)
      } else {
        const {
          data: { campaigns }
        } = await this.$Campaign.fetchCampaignList({ limit: 100 })
        this.campaignOptions = campaigns
      }
      if (this.defaultValues.campaignId) {
        this.scheme.campaign.value = this.defaultValues.campaignId
      }

      if (this.defaultValues.segmentId) {
        this.scheme.segment.value = this.defaultValues.segmentId
      }
      if (this.task.segmentId) await this.getSegmentGroups(this.task.segmentId)
    } catch (e) {
      this.$store.errorNotification = e
    }
    this.$store.loading = false
  },
  methods: {
    async fetchSegmentList (campaignId) {
      this.$store.loading = true
      try {
        const selectedCampaign = this.campaignOptions.find(
          c => c.id === campaignId
        )
        const { data } = await this.$Campaign.fetchSegmentList({
          segmentRequired: selectedCampaign.segmentIds
        })
        if (
          this.task.segmentId &&
          !data.find(({ id }) => this.task.segmentId === id)
        ) {
          const { data: segment } = await this.$Campaign.fetchSegmentDetail({
            segmentId: this.task.segmentId
          })
          data.push(segment)
        }
        const filterSegments = (segments, targetIds) => {
          const segmentSets = targetIds.reduce(
            (set, segmentId) => set.add(segmentId),
            new Set()
          )
          return segments.filter(({ id }) => segmentSets.has(id))
        }
        this.campaignSegments = filterSegments(
          data,
          selectedCampaign.segmentIds
        )
      } catch (e) {
        this.$store.errorNotification = e
      }
      this.$store.loading = false
    },
    async getSegmentGroups (segmentId) {
      this.$store.loading = true
      try {
        const { data } = await this.$Campaign.fetchSegmentDetail({
          segmentId
        })
        this.selectedSegment = data
        if (this.defaultValues.groupId) {
          const defaultGroup = this.selectedSegment.groups.find(
            g => g.groupId === this.defaultValues.groupId
          )
          if (defaultGroup && defaultGroup.isSend === 1) {
            this.scheme.group.value = this.defaultValues.groupId
          }
        }
      } catch (e) {
        this.$store.errorNotification = e
      }
      this.$store.loading = false
    },
    async updateTemplateOptions (channel) {
      if (this.isOutbound) {
        this.form.template = ''
      }
    },
    openSendingTest () {
      if (!this.scheme.channel.value) {
        return (this.$store.errorNotification = 'Please select channel first')
      }
      if (!this.form.template) {
        return (this.$store.errorNotification = 'Please select template first')
      }
      if (!this.templateContents[this.form.template]) {
        return (this.$store.errorNotification = this.$t(
          'Task.form.template_missing_error'
        ))
      }
      this.dialogSendingTest.value = true
    },
    onScheduleChange ({ schedule, startTime, endTime }) {
      this.data.startTime = startTime
      this.data.endTime = endTime
      this.taskSchedule = schedule
    },
    onDatetimePickerChange (data) {
      // We have only 1 execute time for now
      this.data.startTime = localDateToISOString(data.startDate)
      this.data.endTime = localDateToISOString(data.startDate)
    },
    validateDynamicInfo () {
      const segment = this.campaignSegments.find(
        ({ id }) => id === this.scheme.segment.value
      )
      const { dynamic_information_1: d1, dynamic_information_2: d2 } =
        segment.dynamicInformations || {}
      const segmentD1 = d1 === 'Y'
      const segmentD2 = d2 === 'Y'
      const templateId = this.form.template
      if (!templateId) {
        return true
      }
      const { content } = this.templateContents[templateId]
      const templateD1 = content.includes('dynamicInformation1')
      const templateD2 = content.includes('dynamicInformation2')
      return (!templateD1 || segmentD1) && (!templateD2 || segmentD2)
    },
    validate () {
      if (!this.$refs.form.$children[0].validate()) {
        throw this.$t('Task.form.validation_failed_general')
      }
      this.$refs.schedule.validate()
      if (!this.data.startTime || !this.data.endTime) {
        throw this.$t('Task.form.execute_time_missing_error')
      }
      if (new Date(this.data.endTime) < new Date()) {
        throw new Error(
          'StartTime should be greater than or equal to current time'
        )
      }
      if (
        !(
          new Date(this.selectedCampaign.startTime) <=
            new Date(this.data.startTime) &&
          new Date(this.selectedCampaign.endTime) >= new Date(this.data.endTime)
        )
      ) {
        throw this.$t('Task.form.execute_time_invalid_error', {
          startTime: formatDatetimeFromISOString(
            this.selectedCampaign.startTime
          ),
          endTime: formatDatetimeFromISOString(this.selectedCampaign.endTime)
        })
      }
      if (
        !this.isOutbound &&
        !this.isExclusiveProducts &&
        !this.form.template
      ) {
        this.form.template = ''
        throw this.$t('Task.form.template_missing_error')
      }
      if (
        !this.segmentOptionsItems.some(
          item => item.value === this.scheme.segment.value
        )
      ) {
        this.scheme.segment.value = ''
        throw this.$t('Task.form.segment_missing_error')
      }
      const segment = this.campaignSegments.find(
        item => item.id === this.scheme.segment.value
      )
      if (!segment.isScheduled && this.taskSchedule) {
        throw this.$t('Task.form.segment_schedule_error')
      }
      if (!this.validateDynamicInfo()) {
        throw new Error(
          'Dynamic Informations between segment and template does not match'
        )
      }
      if (
        !this.groupOptionsItems.some(
          item => item.value === this.scheme.group.value
        )
      ) {
        this.scheme.group.value = ''
        throw this.$t('Task.form.group_missing_error')
      }
      this.isEditable &&
        greaterThanCurrentTime.call(
          this,
          this.data.startTime,
          dataPlatform.BUFFER_HOUR[this.$store.env]
        )
    },
    async submit (defaultStatus) {
      this.$store.loading = true
      const status = this.data.status || defaultStatus
      try {
        this.validate()
        const templateId = this.form.template
        const params = {
          name: this.scheme.taskName.value,
          startTime: this.data.startTime,
          endTime: this.data.endTime,
          schedule: this.taskSchedule,
          segmentId: this.scheme.segment.value,
          groupId: this.scheme.group.value,
          channel: this.scheme.channel.value,
          templateId,
          status,
          campaignId: this.scheme.campaign.value,
          messageType: this.scheme.messageType.value
        }
        if (status === STATUS.approved && templateId) {
          // FIXME: This is a tmp workaround since backend is not able to get compiled html.
          const { content } = this.templateContents[templateId]
          if (params.channel === 'SMS') {
            params.taskContent = content
          }
          if (params.channel === 'EDM') {
            params.taskContent = convertTemplateToHtml(JSON.parse(content))
          }
        }
        if (this.task.id) {
          await this.$Task.updateTask({
            id: this.task.id,
            ...params
          })
          this.$store.successNotification = 'Task successfully updated'
        } else {
          await this.$Task.createTask(params)
          this.$store.successNotification = 'Task successfully created'
        }
        this.$router.go(-1)
      } catch (e) {
        this.$store.errorNotification = e
      }
      this.$store.loading = false
    }
  }
}
</script>

<style lang="stylus" scoped>
.filterHint {
  display: inline-block;
  margin: 0 0 20px;
  width: 100%;
  word-break: break-word;
  .placeholder {
    color: $light-grey;
  }
  .longText {
    max-height: 150px;
    overflow: auto;
    display: inline-block;
  }

  @media screen and (min-width: 768px){
    &.desktop-align-right {
      position: absolute;
      right: 0;
      top: 75px;
      width: auto;
      font-weight: 500;
    }
  }
}

.datetime_wrapper >>> .dateTimePickerWrapper {
  .calendarTrigger {
    min-width: 100%;
  }
}
</style>
